* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#hove {
  background: white !important;
  color: blue !important;
}
